.underline-on-hover {
  position: relative;
}

.underline-on-hover::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 2px;
  background-color: rgba(255, 176, 189, 0.85);
  visibility: hidden;
  transform: scaleX(0);
  transition: transform 0.1s ease-in-out;
}

.underline-on-hover--hover::after {
  visibility: visible;
  transform: scaleX(1);
}
